import React from "react";
import { BuildType } from "../types/build-type";
import { capitalizeString, setTitle } from "../helpers";
import { BuildList } from "./build-list";
import { getCurrentProduct } from "./product-selector";

require("../styles/builds-page.css");

export const BuildsPage: React.FC = () => {
  setTitle("Builds");

  let types: BuildType[] = ["daily"];

  if (getCurrentProduct() === 1) types.push("alpha", "beta", "main", undefined);

  return (
    <div id="builds-page">
      {types.map((t) => {
        return (
          <div key={t}>
            <b>{capitalizeString(t || "unknown")}</b>
            <BuildList version={t} />
          </div>
        );
      })}
    </div>
  );
};
