import { Spinner } from "react-bootstrap"

require("../styles/centered-spinner.css");

export const CenteredSpinner: React.FC = () => {
  return (
    <div className="centered-spinner">
      <Spinner animation="border" />
    </div>
  )
}