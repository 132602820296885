import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navbar } from "./controls/navbar";
import { TestList } from "./controls/test-list";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BuildsPage } from "./controls/builds-page";
import { BuildReport } from "./controls/build-report";
import { BuildVerification } from "./controls/build-verification";
import { StationsPage } from "./controls/stations-page";
import { NotFound } from "./pages/not-found";
import { ResultPage } from "./pages/result";
import { TestPage } from "./pages/test";

require("./styles/main.css");

export default function App() {
  return (
    <>
      <Navbar/>
      <div id="page-content">
        <Router>
          <Routes>
            <Route index element={<TestList/>} />
            <Route path="/tests" element={<TestList/>}/>
            <Route path="/tests/:id" element={<TestPage />} />
            <Route path="/builds" element={<BuildsPage/>} />
            <Route path="/builds/:id/results" element={<BuildReport/>} />
            <Route path="/builds/:id/verification" element={<BuildVerification/>} />
            <Route path="/results/:id" element={<ResultPage />} />
            <Route path="/stations" element={<StationsPage/>} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}
