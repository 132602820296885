import { formatDateString } from "../helpers";
import { Build } from "../interfaces/build";

export const BuildCard: React.FC<{ b: Build }> = (props) => {
  if (props.b.product_id === 1) return <PA6BuildCard build={props.b} />;
  if (props.b.product_id === 2) return <PAGRIDBuildCard build={props.b} />;

  return (
    <>
      <b>{props.b.number}</b>
      <a href={`/builds/${props.b.id}/results`}>Results</a>{" "}
      <div className="build-details">
        <div>{formatDateString(props.b.created_at)}</div>
        <span>this build belongs to an unknown product</span>
      </div>
    </>
  );
};

const PA6BuildCard: React.FC<{ build: Build }> = (props) => {
  const toDrawVerification =
    props.build.version === "main" || props.build.version === "beta";
  return (
    <>
      <b>{props.build.number}</b>
      <a href={`/builds/${props.build.id}/results`}>Results</a>{" "}
      {toDrawVerification ? (
        <a href={`/builds/${props.build.id}/verification`}>Verification</a>
      ) : null}
      <div className="build-details">
        <div>{formatDateString(props.build.created_at)}</div>
        <div>{`pa: ${props.build.pa_gitsha || "[none]"}`}</div>
        <div>{`webui: ${props.build.webui_gitsha || "[none]"}`}</div>
        <div>{`dicts: ${props.build.dicts_gitsha || "[none]"}`}</div>
        <div>{`help: ${props.build.help_gitsha || "[none]"}`}</div>
      </div>
    </>
  );
};

const PAGRIDBuildCard: React.FC<{ build: Build }> = (props) => {
  return (
    <>
      <b>{props.build.number}</b>
      <a href={`/builds/${props.build.id}/results`}>Results</a>{" "}
      <div className="build-details">
        <div>{formatDateString(props.build.created_at)}</div>
        <div>{`pa: ${props.build.pa_gitsha || "[none]"}`}</div>
        <div>{`tm: ${props.build.tm_gitsha || "[none]"}`}</div>
        <div>{`webui: ${props.build.webui_gitsha || "[none]"}`}</div>
        <div>{`dicts: ${props.build.dicts_gitsha || "[none]"}`}</div>
        <div>{`shared: ${props.build.shared_gitsha || "[none]"}`}</div>
        <div>{`hadoop: ${props.build.hadoop_gitsha || "[none]"}`}</div>
      </div>
    </>
  );
};
