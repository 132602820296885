import axios from "axios";
import { useEffect, useState } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { redirectToLogin } from "../helpers";
import { Product } from "../interfaces/product";
import { Paths } from "../paths";

export const getCurrentProduct = (): number => {
  const id = localStorage.getItem("current-product");
  return id ? Number(id) : 1;
};

const setCurrentProduct = (id: number) => {
  localStorage.setItem("current-product", String(id));
};

export const ProductSelector: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([] as Product[]);

  const switchProduct = (id: number) => {
    setCurrentProduct(id);
    window.location.reload();
  };

  useEffect(() => {
    axios
      .get(Paths.DB.Products)
      .then((resp) => {
        setProducts(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
      });
  }, []);

  const currentProductName = (): string => {
    let product = products.find((p) => p.id === getCurrentProduct());

    if (!product) {
      product = products[0];
      switchProduct(product.id);
    }

    return product.name;
  };

  if (isLoading)
    return (
      <Nav.Link href="#" disabled={true}>
        Product: Loading...
      </Nav.Link>
    );

  return (
    <NavDropdown title={`Product: ${currentProductName()}`}>
      <>
        {products.map((product) => {
          if (product.id === getCurrentProduct()) return null;

          return (
            <NavDropdown.Item
              key={product.id}
              onClick={() => {
                switchProduct(product.id);
              }}
            >
              {product.name}
            </NavDropdown.Item>
          );
        })}
      </>
    </NavDropdown>
  );
};
