import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { redirectToLogin } from "../helpers";
import { Task } from "../interfaces/tasks-data";
import { Paths } from "../paths";
import { PHAB } from "../phab";

export const TaskList = (props: { tasks: string[] }) => {
  const [phabBaseURL, setPhabBaseURL] = useState("");

  const [tasksData, setTasksData] = useState([] as Task[]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.tasks.length === 0) {
      setIsLoading(false);
      return;
    }

    let url = `${PHAB.Paths.ManiphestSearch}?`;

    url += props.tasks
      .map((task, i) => {
        return `constraints[ids][${i}]=${task.substring(1)}`;
      })
      .join("&");

    axios.get(url).then((resp) => {
      setTasksData(resp.data.result.data);
    });

    axios
      .get(Paths.Env)
      .then((resp) => {
        setIsLoading(false);
        setPhabBaseURL(resp.data.phab.baseURL);
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
      });
  }, []);

  if (isLoading) return <Spinner animation="border" />;

  if (props.tasks.length === 0) return <>No tasks</>

  return (
    <table>
      <tbody>
        {tasksData.map((task) => {
          return (
            <tr>
              <td>
                <a target="_blank" href={`${phabBaseURL}/T${task.id}`}>
                  {`T${task.id}`}
                </a>
              </td>
              <td>{task.fields?.name}</td>
              <td>{task.fields?.status.name.toUpperCase()}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
