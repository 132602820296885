import React from "react";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import BPNavbar from "react-bootstrap/esm/Navbar";
import { ProductSelector } from "./product-selector";
import { UserPanel } from "./user-panel";

require("../styles/main-navbar.css");

export class Navbar extends React.Component {
  render() {
    return (
      <BPNavbar id="main-navbar" bg="light" expand="lg">
        <Container>
          <BPNavbar.Brand href="/">Test Center</BPNavbar.Brand>
          <BPNavbar.Toggle aria-controls="basic-navbar-nav" />
          <BPNavbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <ProductSelector />
              <Nav.Link href="/tests">Tests</Nav.Link>
              <Nav.Link href="/builds">Builds</Nav.Link>
              <Nav.Link href="/stations">Test Stations</Nav.Link>
            </Nav>
          </BPNavbar.Collapse>

          <BPNavbar.Toggle />

          <BPNavbar.Collapse className="justify-content-end">
            <Nav.Item>
              <UserPanel />
            </Nav.Item>
          </BPNavbar.Collapse>
        </Container>
      </BPNavbar>
    );
  }
}
