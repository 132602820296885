import { getCurrentProduct } from "./controls/product-selector";
import { Paths } from "./paths";
import { Product } from "./types/product";

export function setTitle(title: string) {
  const product = Product[getCurrentProduct()] || 'UNKNOWN'
  document.title = `${product} ∙ ${title}`
}

export function truncateString(input: string, length: number = 50, suffix: string = ".."): string {
  if (input.length <= length) {
    return input;
  }

  const cutLength = length - suffix.length;
  return input.slice(0, cutLength) + suffix;
}

export function redirectToLogin(saveFromURL: boolean = true) {
  let loginPath = Paths.Login

  if (saveFromURL) loginPath += `?from=${window.location.href}`

  window.location.href = loginPath;
}

export function formatDateString(dateString: string | Date): string {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
}

export const capitalizeString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const dateToLocaleString = (date: Date | number | string | null | undefined) => {
  if (!date) return '<unknown>'

  return new Date(date).toLocaleString('ru-RU')
}

export const formatDuration = (duration: number): string => {
  const milliseconds = Math.floor((duration % 1000) / 100)
  let seconds = Math.floor((duration / 1000) % 60)
  let minutes = Math.floor((duration / (1000 * 60)) % 60)
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  let hoursS = `${hours}`.padStart(2, "0");
  let  minutesS = `${minutes}`.padStart(2, "0");
  let secondsS = `${seconds}`.padStart(2, "0");

  return `${hoursS}:${minutesS}:${secondsS}.${milliseconds}`;
}

export const formatFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}
