import React from 'react'
import { useState } from "react"
import Form from 'react-bootstrap/esm/Form'

export const Checkbox: React.FC<{label: string, checked: boolean, id?: string, title?: string, onChange?: () => void }> = (props) => {
  const [checked, setChecked] = useState(props.checked)

  const onChange = () => {
    if (props.onChange) props.onChange();

    setChecked(!checked);
  }

  return (
    <Form.Check
      type="checkbox"
      id={props.id}
      title={props.title}
      label={props.label}
      checked={checked}
      onChange={onChange}
    />
  )
}
