import axios from "axios";
import React, { EffectCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { formatDuration, redirectToLogin } from "../helpers";
import { ResultData } from "../interfaces/result";
import { Paths } from "../paths";
import { TaskList } from "./task-list";

export const Result: React.FC<{ resultID: string }> = (props) => {
  const initialResultData: ResultData = {};
  const [resultData, setResultData] = useState(initialResultData);

  const [isLoading, setIsLoading] = useState(true);

  const newTaskFieldRef = React.createRef<HTMLInputElement>();
  const newTaskSubmitRef = React.createRef<HTMLInputElement>();

  const loadData: EffectCallback = () => {
    setIsLoading(true);

    const url = Paths.DB.Result(props.resultID);
    axios
      .get(url)
      .then((resp) => {
        setResultData(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
      });
  };

  useEffect(loadData, []);

  const validateTask: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let taskID = e.currentTarget.value;

    try {
      const url = new URL(taskID);
      taskID = url.pathname.split("/").slice(-1)[0];
    } catch (_) {}

    if (/^T\d+$/.test(taskID) || taskID === "") {
      e.currentTarget.style.backgroundColor = "white";
      newTaskSubmitRef.current?.removeAttribute("disabled");
      e.currentTarget.value = taskID;
      return;
    }

    e.currentTarget.style.backgroundColor = "#FFCCCB";
    newTaskSubmitRef.current?.setAttribute("disabled", "disabled");
  };

  const appendTask: React.FormEventHandler = (e) => {
    e.preventDefault();

    const taskId = newTaskFieldRef.current?.value;
    if (!taskId) return;

    axios
      .post(Paths.DB.ResultTasks(props.resultID) + `?task_id=${taskId}`)
      .then(loadData)
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
        // TODO: handle!
      });
  };

  const drawTableBody = () => {
    return (
      <tbody>
        <tr>
          <td>ID: </td>
          <td>{resultData.id}</td>
        </tr>
        <tr>
          <td>Start time: </td>
          <td>{resultData.start_time?.toString()}</td>
        </tr>
        <tr>
          <td>End time: </td>
          <td>{resultData.end_time?.toString()}</td>
        </tr>
        <tr>
          <td>Duration: </td>
          <td>{formatDuration(1000 * (resultData.duration || 0))}</td>
        </tr>
        <tr>
          <td>Status: </td>
          <td>{resultData?.status}</td>
        </tr>
        <tr>
          <td>Error: </td>
          <td>{resultData?.error || "[none]"}</td>
        </tr>
        <tr>
          <td>Tasks: </td>
          <td>
            <TaskList tasks={resultData.tasks || []} />

            <form onSubmit={appendTask}>
              <input type="text" placeholder="ID or URL" onChange={validateTask} ref={newTaskFieldRef} />
              <input type="submit" value="Add" ref={newTaskSubmitRef} />
            </form>
          </td>
        </tr>
      </tbody>
    );
  }

  if (isLoading) return (
    <div id="build-report-spinner-container">
      <Spinner animation="border" />
    </div>
  )

  return <table>{drawTableBody()}</table>;
}
