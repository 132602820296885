import React, { useRef, MouseEventHandler, useEffect, useState, UIEventHandler, ChangeEventHandler } from "react";
import { BuildReportData } from "../interfaces/build-report-data";
import { ResultModal } from "./result-modal";
import axios from "axios";
import Table from "react-bootstrap/esm/Table";
import Button from "react-bootstrap/esm/Button";
import InputGroup from "react-bootstrap/esm/InputGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import { setTitle, redirectToLogin } from "../helpers";
import { Paths } from "../paths";
import { useParams } from "react-router-dom"
import { CenteredSpinner } from "./centered-spinner";
import { BuildReportFilterModal, dropStoredFilterParams, getStoredFilterParams } from "./build-report-filter-modal";
import { getCurrentProduct } from "./product-selector";
import { TestResult } from "../types/test-result";

interface BuildReportScrollState {
  isScrolling: boolean,
  isMouseDown: boolean,
  toPreventResultModal: boolean,
  top: number,
  left: number,
  x: number,
  y: number,
}

export const BuildReport = () => {
  require("../styles/build-report.css");

  const ref = useRef(null);

  const params = useParams();

  const buildID = params.id || '2';

  setTitle(`Build report`);

  let repData: BuildReportData[] = [];
  const [reportData, setReportData] = useState(repData);

  const [isLoading, setIsLoading] = useState(true);

  const [resultID, setResultID] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  const [showFilterModal, setShowFilterModal] = useState(false);
  const handleCloseFilterModal = () => setShowFilterModal(false)
  const handleShowFilterModal = () => setShowFilterModal(true)

  // drag-n-scroll
  const initScrollState: BuildReportScrollState = {
    isScrolling: false,
    isMouseDown: false,
    toPreventResultModal: false,
    top: 0,
    left: 0,
    x: 0,
    y: 0,
  }
  const [scrollState, setScrollState] = useState(initScrollState);

  const onMouseDown: MouseEventHandler = (e) => {
    setScrollState({
      ...scrollState,
      isMouseDown: true,
      toPreventResultModal: false,
      left: e.currentTarget.scrollLeft,
      top: e.currentTarget.scrollTop,
      x: e.clientX,
      y: e.clientY,
    })
  };

  const onMouseUp: MouseEventHandler = (e) => {
    setScrollState({ ...scrollState, isScrolling: false, isMouseDown: false });
  };

  const onMouseMove: MouseEventHandler = (e) => {
    if (scrollState.isMouseDown && ref.current) {
      e.preventDefault()

      setScrollState({...scrollState, toPreventResultModal: true, isScrolling: true});

      const dx = e.clientX - scrollState.x;
      const dy = e.clientY - scrollState.y;

      e.currentTarget.scrollTop = scrollState.top - dy;
      e.currentTarget.scrollLeft = scrollState.left - dx;
    }
  };

  const onMouseLeave: MouseEventHandler = () => {
    if (scrollState.isScrolling) setScrollState({ ...scrollState, isScrolling: false, isMouseDown: false, toPreventResultModal: false});
  };
  // drag-n-scroll end

  const dbURL = () => {
    const searchParams = new URLSearchParams();

    const filter = getStoredFilterParams();

    for (let id in filter.statuses) {
      if (filter.statuses[id].show) {
        searchParams.append('status[]', id);
      }
    }

    for (let id in filter.tags) {
      if (filter.tags[id].show) {
        searchParams.append('tags.id[]', id);
      }
    }

    for (let id in filter.test_stations) {
      if (filter.test_stations[id].show) {
        searchParams.append('test_station_id[]', id);
      }
    }

    for (let id in filter.test_types) {
      if (filter.test_types[id].show) {
        searchParams.append('tests.kind[]', id);
      }
    }

    return `${Paths.DB.ProductBuilds(getCurrentProduct())}/${buildID}/results?${searchParams.toString()}`;
  }

  const filterTestsByName: ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const table = document.getElementById("build-report");

    let headers: HTMLElement[] = [];
    const iterator = document.evaluate(`//th[@class='test-name-cell']`, table as Node);
    let row = iterator.iterateNext();

    while (row) {
      const e = row as HTMLElement
      headers.push(e)

      row = iterator.iterateNext();
    }

    headers.forEach((h) => {
      if (h.textContent && h.parentElement) {
        let hidden = false;

        if (e.target.value.length > 0) {
          hidden = !h.textContent.toString().toLowerCase().includes(e.target.value.toLowerCase());
        }

        h.parentElement.hidden = hidden;
      }
    });
  }

  useEffect(() => {
    axios.get(dbURL())
    .then(resp => {
      setReportData(resp.data);
      setIsLoading(false);
    })
    .catch(err => {
      if (err.response.status === 403) redirectToLogin();
    })
  }, []);

  function drawBuildHeaders() {
    var headers: JSX.Element[] = [];

    headers.push(
      <th className="very-special-cell" rowSpan={2} >
        <InputGroup className="mb-3" size="sm">
          <FormControl
            placeholder="test name"
            aria-label="test name"
            aria-describedby="basic-addon2"
            onChange={filterTestsByName}
          />
          <Button title="Advanced filter" variant="outline-secondary" onMouseUp={handleShowFilterModal}>
            <i className="bi bi-funnel-fill" />
          </Button>
        </InputGroup>
      </th>
    );

    reportData.forEach((build) => {
      let colSpan = build.test_stations.length;
      let title = build.build_number;

      headers.push(
        <th className="build-header" colSpan={colSpan}>
          {title}
        </th>
      );
    });

    return headers;
  }

  function drawPCHeaders() {
    var headers: JSX.Element[] = [];

    reportData.forEach((build) => {
      build.test_stations.forEach((station) => {
        headers.push(
          <td title={`${station.name}\n${station.os || "<unknown>"}`} className={`pc-header`}>
            {station.name}
          </td>
        );
      });
    });

    return headers;
  }

  function drawResultRows() {
    var rows: JSX.Element[] = [];

    if (reportData && reportData.length > 0) {
      for (var uuid in reportData[0].test_results) {
        rows.push(<tr>{drawCellsForTest(uuid)}</tr>);
      }
    }

    return rows;
  }

  function resetHoverHiglightingFor(resCell: HTMLElement, on: boolean = true) {
    const pcClass = resCell.classList.item(0);
    const buildClass = resCell.classList.item(1);
    if (pcClass && buildClass) {
      const elems = document.querySelectorAll(`.${pcClass}.${buildClass}`);
      const count = elems.length;
      for (let i = 0; i < count; i++) {
        const e = elems.item(i);
        if (!e) continue;
        on ? e.classList.add("hovered") : e.classList.remove("hovered");
      }
    }
  }

  function drawCellsForTest(uuid: string) {
    var cells: JSX.Element[] = [];

    if (reportData && reportData.length > 0) {
      cells.push(
        <th
          className="test-name-cell"
          title={reportData[0].test_results[uuid].test_name}
        >
          <img src={`/logo-${reportData[0].test_results[uuid].type}.svg`} alt="?"/>
          <div className="name">{reportData[0].test_results[uuid].test_name}</div>
          <div className="tags">{reportData[0].test_results[uuid].tags.join(', ')}</div>
        </th>
      );
    }

    reportData.forEach((build) => {
      build.test_stations
        .map((station) => station.id)
        .forEach((stationID) => {
          const count =
            build.test_results[uuid]?.station_results[stationID]?.length;
          var tsResults: JSX.Element[] = [];

          if (count && count > 0) {
            build.test_results[uuid].station_results[stationID].forEach((res) => {
              let className = `result result-${TestResult[res.status] || "unknown" }`

              tsResults.push(
                <span
                  className={className}
                  onMouseUp={ () => {
                    if (scrollState.toPreventResultModal) return;

                    setResultID(res.id);
                    handleShowModal();
                  }}
                  style={{width: `${(100 - 10 - 1 * count) / count}%`}}
                />
              );
            });
          }

          cells.push(
            <td
              onMouseEnter={(e) => {
                resetHoverHiglightingFor(e.currentTarget);
              }}
              onMouseLeave={(e) => {
                resetHoverHiglightingFor(e.currentTarget, false);
              }}
              className={`pc-${stationID} b-${build.build_id} report-cell`}
            >
              {tsResults}
            </td>
          );
        });
    });

    return cells;
  }

  if (isLoading) return <CenteredSpinner/>

  if (reportData.length == 0) return (
    <div id="build-report-container">
      <div>Nothing to display</div>
      <a href="#" onClick={ () => { dropStoredFilterParams(); window.location.reload() }}>Reset filters</a>
    </div>
  )

  setTitle(`${reportData[0].build_number} report`)

  return (
    <div id="build-report-container"
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <Table className="table-hover" bordered id="build-report">
        <thead>
          <tr>{drawBuildHeaders()}</tr>
          <tr>{drawPCHeaders()}</tr>
        </thead>
        <tbody>{drawResultRows()}</tbody>
      </Table>

      <ResultModal show={showModal} onHide={handleCloseModal} onShow={handleShowModal} resultID={resultID.toString()}/>
      <BuildReportFilterModal buildID={buildID} show={showFilterModal} onHide={handleCloseFilterModal} onShow={handleShowFilterModal}/>
    </div>
  );
}
