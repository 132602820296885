import React from "react";
import { setTitle } from "../helpers";
import { useParams } from "react-router-dom";
import { Test } from "../controls/test";
import { NotFound } from "./not-found";

require("../styles/test-page.css");

export const TestPage: React.FC = () => {
  const params = useParams();
  const testID = params.id;

  setTitle("Test");

  if (!testID) {
    return <NotFound />;
  }

  return (
    <div id="test-page">
      <Test testID={testID as string} />
    </div>
  );
};
