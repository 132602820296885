import React from "react";
import { PHAB } from "../phab";
import { redirectToLogin } from "../helpers";
import { PhabRequestResult } from "../interfaces/phabricator-reqest-result";
import { User } from "../interfaces/user";
import { CenteredSpinner } from "./centered-spinner";

require('../styles/user-panel.css');

export class UserPanel extends React.Component {
  state: { user: User | undefined } = { user: undefined };

  constructor(props: {}) {
    super(props);
    this.fetch = this.fetch.bind(this);
  }

  async fetch() {
    const response = await fetch(`${PHAB.Paths.UserWhoAmI}`);
    const result: PhabRequestResult = await response.json();

    if (result.error_code === PHAB.Errors.ErrInvalidAuth) {
      redirectToLogin();
      return;
    }

    this.setState({ user: result.result as User });
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.user) {
      return (
        <div className="user-panel">
          <img
            src={this.state.user?.image || "unknown-user.jpg"}
            alt="unknown-user"
          ></img>{" "}
          <div>
            <a href={this.state.user?.uri || "#"} target="_blank" rel="noreferrer">
              {this.state.user?.realName || "???"}
            </a>
            <a href="/logout">Logout</a>
          </div>
        </div>
      );
    }

    return <CenteredSpinner/>;
  }
}
