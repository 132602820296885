import axios from "axios";
import React from "react";
import { redirectToLogin } from "../helpers";
import { Build } from "../interfaces/build";
import { Paths } from "../paths";
import { BuildType } from "../types/build-type";
import { BuildCard } from "./build-card";
import { CenteredSpinner } from "./centered-spinner";
import { getCurrentProduct } from "./product-selector";

interface BuildListProps {
  version?: BuildType;
  limit?: number;
}

const BuildListDefaultProps: BuildListProps = {
  version: 'daily',
  limit: 10,
};

export class BuildList extends React.Component<BuildListProps> {
  state: { builds: Build[] | undefined } = { builds: undefined };

  constructor(props: BuildListProps) {
    super(props);

    this.fetch = this.fetch.bind(this);
  }

  async fetch() {
    axios
      .get(this.buildsPath())
      .then((resp) => {
        this.setState({ builds: resp.data });
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
      });
  }

  buildsPath(): string {
    const urlProps = { ...BuildListDefaultProps, ...this.props };
    const urlParams = new URLSearchParams();

    if (urlProps.version) urlParams.append("version", urlProps.version);
    if (urlProps.limit) urlParams.append("limit", urlProps.limit.toString());

    return `${Paths.DB.ProductBuilds(getCurrentProduct())}?${urlParams.toString()}`;
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.builds) {
      return <table>{this.drawTableBody()}</table>;
    }

    return <CenteredSpinner />;
  }

  private drawTableBody() {
    return (
      <tbody>
        {this.state.builds?.map((record) => (
          <tr key={record.id}>
            <td>
              <BuildCard b={record} />
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}
