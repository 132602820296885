import { setTitle } from "../helpers";

export const NotFound = () => {
  require("../styles/not-found.css");

  setTitle("Not found");

  return (

    <div id="not-found-page">
      <div>🤷🏻‍♂️ 404</div>
      <a href="javascript:history.back()">🔙</a>
    </div>
  );
};
