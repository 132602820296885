export namespace PHAB {
  export namespace Errors {
    export const ErrInvalidAuth = "ERR-INVALID-AUTH";
  }

  export namespace Paths {
    const root = "/phab"
    export const UserWhoAmI = `${root}/user.whoami`
    export const ManiphestSearch = `${root}/maniphest.search`
  }
}
