import React, {  } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { Result } from "./result";

export const ResultModal: React.FC<{show: boolean, onHide: () => void, onShow: () => void, resultID: string}> = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Result resultID={props.resultID} />
        </Modal.Body>
        <Modal.Footer>
          <a className="btn btn-secondary" href={`/results/${props.resultID}`}>More</a>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
