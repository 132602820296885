import axios from "axios";
import React, { useEffect, useState } from "react";
import { formatDateString, redirectToLogin, setTitle } from "../helpers";
import { Interfaces } from "../interfaces/expectations";
import { iTest } from "../interfaces/test";
import { Paths } from "../paths";
import { CenteredSpinner } from "./centered-spinner";
import { FileDrop } from "./file-drop";

enum CompraisionFormat {
  "less than" = -2,
  "less or equal to" = -1,
  "exactly" = 0,
  "more or equal to" = 1,
  "more than" = 2,
}

export const Test: React.FC<{ testID: string }> = (props) => {
  const [testData, setTestData] = useState<iTest | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const url = Paths.DB.Test(props.testID);
    axios
      .get(url)
      .then((resp) => {
        setTestData(resp.data as iTest);
        if (testData) setTitle(testData.name);
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
        // TODO handle
      });

    setIsLoading(false);
  }, []);

  const drawExpectation = (e: Interfaces.Expectation) => {
    switch (e.type) {
      case "project-execution-status":
        return `• Execution status: ${
          (e.expectation as Interfaces.ExecutionStatus).status
        }`;
      case "color-nodes-count":
        return `• Count of ${
          (e.expectation as Interfaces.NodesCount).color
        } nodes must be ${
          CompraisionFormat[(e.expectation as Interfaces.NodesCount).operation]
        } ${(e.expectation as Interfaces.NodesCount).count}`;
      case "node-rows-count":
        return `• '${
          (e.expectation as Interfaces.DatasetRowsCount).node
        }' node must contain ${
          CompraisionFormat[
            (e.expectation as Interfaces.DatasetRowsCount).operation
          ]
        } ${(e.expectation as Interfaces.DatasetRowsCount).count} rows`;
      default:
        return "<unknown type of expectaion>";
    }
  };

  const drawTableBody = (test: iTest) => {
    return (
      <tbody>
        <tr>
          <td>ID: </td>
          <td>{test.id}</td>
        </tr>
        <tr>
          <td>Name: </td>
          <td>{test.name}</td>
        </tr>
        <tr>
          <td>Location: </td>
          <td>{test.location}</td>
        </tr>
        <tr>
          <td>State: </td>
          <td>{test.state}</td>
        </tr>
        <tr>
          <td>Kind: </td>
          <td>{test.kind}</td>
        </tr>
        <tr>
          <td>Expectations: </td>
          <td>
            {test.expectations.map((e) => (
              <div>{drawExpectation(e)}</div>
            ))}
          </td>
        </tr>
        <tr>
          <td>Note: </td>
          <td>{test.note || "[none]"}</td>
        </tr>
        <tr>
          <td>Created: </td>
          <td>{formatDateString(test.created_at)}</td>
        </tr>
        <tr>
          <td>Updated: </td>
          <td>{formatDateString(test.updated_at)}</td>
        </tr>
      </tbody>
    );
  };

  if (isLoading) return <CenteredSpinner />;

  if (!testData) return <>Failed to acquire the test data</>;

  return (
    <>
      <table>{drawTableBody(testData)}</table>
      <FileDrop testId={props.testID} />
    </>
  );
};
