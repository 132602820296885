import React from "react";
import { setTitle } from "../helpers";
import { StationsList } from "./stations-list";

export const StationsPage: React.FC = () => {
  require("../styles/stations-page.css");

  setTitle("Test Stations");

  return (
    <div id="stations-page">
      <StationsList />
    </div>
  );
};
