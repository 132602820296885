import React, { useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import axios from "axios";
import Form from "react-bootstrap/esm/Form";
import { BuildReportFilterParams, filterItems } from "../interfaces/build-report-filter-params";
import { Checkbox } from "./checkbox";
import { redirectToLogin } from "../helpers";
import { Paths } from "../paths";
import merge from "ts-deepmerge";
import { getCurrentProduct } from "./product-selector";

interface BuildReportFilterModalProps {
  buildID: string;
  show: boolean;
  onHide: () => void;
  onShow: () => void;
}

export const getStoredFilterParams = (): BuildReportFilterParams => {
  const paramsString = localStorage.getItem("build-report-filter-params");
  if (!paramsString) return {} as BuildReportFilterParams;

  return JSON.parse(paramsString) as BuildReportFilterParams;
}

const setStoredFilterParams = (params: BuildReportFilterParams) => {
  const currParams = getStoredFilterParams();
  const newParams = merge(currParams, params);
  localStorage.setItem("build-report-filter-params", JSON.stringify(newParams));
}

const extendStoredFilterParams = (params: BuildReportFilterParams) => {
  const currParams = getStoredFilterParams();
  const newParams = merge(params, currParams);
  localStorage.setItem("build-report-filter-params", JSON.stringify(newParams));
}

export const dropStoredFilterParams = () => {
  localStorage.removeItem("build-report-filter-params");
}

export const BuildReportFilterModal: React.FC<BuildReportFilterModalProps> = (props) => {
  const [reportFilterOptionsFetched, setReportFilterOptionsFetched] = useState(false);

  const [reportFilterOptions, setReportFilterOptions] = useState(getStoredFilterParams());

  const [isLoading, setIsLoading] = useState(true);

  const fetchParams = () => {
    const filterParamsURL = Paths.DB.BuildReportFilterParams(getCurrentProduct(), props.buildID);

    axios
      .get(filterParamsURL)
      .then((resp) => {
        setIsLoading(false);
        setReportFilterOptionsFetched(true);
        extendStoredFilterParams(resp.data);
        setReportFilterOptions(getStoredFilterParams());
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();
      });
  };

  const onShowBuildReportFilterModal = () => {
    if (!reportFilterOptionsFetched) fetchParams();

    setReportFilterOptions(getStoredFilterParams());

    props.onShow();
  }

  const onApply = () => {
    if (reportFilterOptions) setStoredFilterParams(reportFilterOptions);

    props.onHide();

    window.location.reload();
  }

  const onReset = () => {
    dropStoredFilterParams();
    window.location.reload();
  }

  const drawCheckboxes = (params: filterItems) => {
    let res: JSX.Element[] = [];

    for(const id in params) {
      res.push(
        <Checkbox
          label={params[id].name}
          title={params[id].name}
          checked={params[id].show}
          onChange={ () => {
            params[id].show = !params[id].show;
          }}
        />
      );
    }

    return res
  }

  const filterOptions = (filterParams: BuildReportFilterParams) => {
    return (
      <div>
        <div>
          <div className="build-result-filter-params-section">
            <b>By statuses:</b>
            <Form.Group className="mb-3">
              {
                drawCheckboxes(filterParams.statuses)
              }
            </Form.Group>
          </div>
          <div className="build-result-filter-params-section">
            <b>By test type:</b>
            <Form.Group className="mb-3">
              {
                drawCheckboxes(filterParams.test_types)
              }
            </Form.Group>
          </div>
          <div>
            <b>By test stations:</b>
            <Form.Group className="mb-3 columns">
              {
                drawCheckboxes(filterParams.test_stations)
              }
            </Form.Group>
          </div>
          <div>
            <b>By tags:</b>
            <Form.Group className="mb-3 columns">
              {
                drawCheckboxes(filterParams.tags)
              }
            </Form.Group>
          </div>
        </div>
      </div>
    )
  }


  const modalBodyContent = () => {
    if (isLoading) return <Spinner animation="border" />

    if (reportFilterOptions) return filterOptions(reportFilterOptions);

    return 'whoops! something went wrong :('
  }

  return (
    <>
      <Modal show={props.show} onShow={onShowBuildReportFilterModal} onHide={props.onHide} dialogClassName="build-report-filter-modal">
        <Modal.Header closeButton>
          <Modal.Title>Advanced filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="adv-filter-content">
          {modalBodyContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onReset}>Reset</Button>
          <Button variant="secondary" onClick={onApply}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};