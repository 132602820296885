import axios from "axios";
import { useRef, useState } from "react";
import { ALLOWED_FILE_EXTENSIONS } from "../const";
import { dateToLocaleString, formatFileSize } from "../helpers";
import { Paths } from "../paths";

export const FileDrop: React.FC<{testId: string}>= (props) => {
  require("../styles/file-drop.css");

  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const commentRef = useRef<HTMLTextAreaElement>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList) return;

    const probablyFile = fileList[0];

    if (!probablyFile) return;

    const ext = probablyFile.name.split(".").slice(1)[0].toLowerCase();
    if (!ALLOWED_FILE_EXTENSIONS.includes(ext)) {
      alert(
        `Bad file type!\nAlowed types are: ${ALLOWED_FILE_EXTENSIONS.join(
          ", "
        )}`
      );
      setFile(undefined);
      return;
    }

    setFile(fileList[0]);
  };

  const removeFile = () => {
    if (fileInputRef.current?.value) fileInputRef.current.value = "";
    setFile(undefined);
  };

  const uploadFile = function (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (!file) return;

    e.currentTarget.setAttribute("disabled", "disabled");
    fileInputRef.current?.setAttribute("disabled", "disabled");
    commentRef.current?.setAttribute("disabled", "disabled");

    const formData = new FormData(formRef.current || undefined);
    formData.append("testdata", file, file.name);

    axios
      .post("/test-upload", formData)
      .catch((err) => {
        console.log(err);
      })
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        e.currentTarget?.removeAttribute("disabled");
        fileInputRef.current?.removeAttribute("disabled");
        commentRef.current?.removeAttribute("disabled");
        setFile(undefined);
      });
  };

  const uploadButton = () => {
    return (
      <button
        onClick={uploadFile}
        type="button"
        disabled={file ? undefined : true}
      >
        Upload!
      </button>
    );
  };

  const cancelLink = () => {
    return (
      <a tabIndex={1000} href="#" onClick={removeFile} className="cancel-link">
        Cancel
      </a>
    );
  };

  const fileInfo = (file: File) => {
    return (
      <div>
        <strong>{file.name}</strong>
        <div>Size: {formatFileSize(file.size)}</div>
        <div> Last modified: {dateToLocaleString(file.lastModified)}</div>
        {cancelLink()}
      </div>
    );
  };

  return (
    <div className="file-drop-form-container">
      <form method="post" action="/test-upload" ref={formRef}>
        Upload a new version
        <div className="file-drop-input-container">
          <input type="file" name="testdata" className="file-drop-input" onChange={onFileChange} ref={fileInputRef} />
          {file ? fileInfo(file) : `Drop a file here (${ALLOWED_FILE_EXTENSIONS.join(", ")})`}
        </div>
        {file ? <textarea placeholder="Comment" ref={commentRef}></textarea> : <></>}
        {file ? uploadButton() : <></>}
        <input type="hidden" name="backPath" value={Paths.Test(props.testId)}></input>
      </form>
    </div>
  );
};
