import React from "react";
import { setTitle, redirectToLogin, truncateString } from "../helpers";
import { iTest } from "../interfaces/test";
import { Paths } from "../paths";
import { CenteredSpinner } from "./centered-spinner";
import { getCurrentProduct } from "./product-selector";

export class TestList extends React.Component {
  state: { tests: iTest[] | undefined } = { tests: undefined };

  constructor(props: {}) {
    super(props);

    setTitle('Tests');

    this.fetch = this.fetch.bind(this);
  }

  async fetch() {
    const response = await fetch(Paths.DB.ProductTests(getCurrentProduct()));

    if (response.status === 403) {
      redirectToLogin();
    }

    const result: iTest[] = await response.json();

    this.setState({ tests: result });
  }

  componentDidMount() {
    this.fetch();
  }

  render() {
    if (this.state.tests) {
      return (
        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Name</td>
              <td>Location</td>
              <td>State</td>
              <td>Kind</td>
              <td>Note</td>
              <td>created_at</td>
              <td>updated_at</td>
            </tr>
          </thead>
          {this.drawTableBody()}
        </table>
      );
    }

    return <CenteredSpinner />;
  }

  private drawTableBody() {
    return (
      <tbody>
        {this.state.tests?.map((record) => (
          <tr key={record.id}>
            <td>{record.id}</td>
            <td title={record.name}>
              <a href={`/tests/${record.id}`}>{truncateString(record.name)}</a>
            </td>
            <td title={record.location}>{record.location ? truncateString(record.location) : "unknown"}</td>
            <td>{record.state}</td>
            <td>{record.kind}</td>
            <td>{record.note || "[none]"}</td>
            <td>{record.created_at.toString()}</td>
            <td>{record.updated_at.toString()}</td>
          </tr>
        ))}
      </tbody>
    );
  }
}
