import axios from "axios";
import { useEffect, useState } from "react";
import { redirectToLogin } from "../helpers";
import { TestStation } from "../interfaces/test-station";
import { Paths } from "../paths";
import { CenteredSpinner } from "./centered-spinner";

export const StationsList: React.FC = () => {
  const def: TestStation[] = [];
  const [testStations, setTestStations] = useState(def);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);

  useEffect(() => {
    axios
      .get(Paths.DB.TestStations)
      .then((resp) => {
        setTestStations(resp.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 403) redirectToLogin();

        setIsFetchError(true);
      });
  }, []);

  if (isLoading) return <CenteredSpinner />;

  if (isFetchError) return <span>Something went wrong :(</span>;

  return (
    <table>
      <thead>
        <tr>
          <td>ID</td>
          <td>Name</td>
          <td>OS</td>
          <td>Token</td>
        </tr>
      </thead>
      <tbody>
        {testStations.map((station) => {
          return (
            <tr>
              <td>{station.id}</td>
              <td>{station.name || '[none]'}</td>
              <td>{station.os || '<unknown>'}</td>
              <td>{station.token}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
