import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { setTitle, redirectToLogin } from "../helpers";
import { Task } from "../interfaces/tasks-data";
import { CenteredSpinner } from "./centered-spinner";

export const BuildVerification = () => {

  const params = useParams();
  const buildID = params.id || '2';

  setTitle(`Verification`);
  let tData: Task[] = [];
  const [tasksData, setTasksData] = useState(tData);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    axios.get(`/db/builds/${buildID}`)
    .then(resp => {
      let url = `/phab/maniphest.search?`;
      const list_tasks = resp.data.whatsnew_tasks;
      for (let i = 0; i < list_tasks.length; i++){
        url += `constraints[ids][${i}]=${list_tasks[i]}&`
      }
      url += `attachments[subscribers]=1`;
      axios.get(url)
      .then(resp => {
        setTasksData(resp.data.result.data);
        setIsLoading(false);
      })
      .catch(err => {
        if (err.response.status === 403) redirectToLogin();
      })
    })
    .catch(err => {
      if (err.response.status === 403) redirectToLogin();
    })       
  }, []);
  
  if (isLoading) return <CenteredSpinner/>

  return (
      <table>
        <thead>
          <tr>
            <td>Tasks</td>
            <td>Testers</td>
          </tr>
        </thead>
        <tbody>
          {drawTasksRows()}
        </tbody>
      </table>
  );

  function drawTasksRows() {
    var rows: JSX.Element[] = [];
  
    const tasks = tasksData;
  
    tasks.forEach((e: any) => {
      let name = e.fields.name;
      const subs = e.attachments.subscribers.subscriberPHIDs;
      const subscibers = subs.toString();
      rows.push(<tr key={name}>
          <td>{name}</td>
          <td>{subscibers}</td>
        </tr>);
    });
  
    return rows;
  }

}
