export namespace Paths {
  export const Login = "/login";
  export const Env = "/env";

  export const Tests = "/tests";
  export const Test = (testId: string) => `/tests/${testId}`;

  export namespace DB {
    export const Root = "/db";

    export const Results = `${Root}/results`;
    export const Result = (id: number | string) => `${Results}/${id}`
    export const ResultTasks = (resultId: number | string) => `${Result(resultId)}/tasks`

    export const Tests = `${Root}/tests`
    export const Test = (id: number | string) => `${Tests}/${id}`;

    export const TestStations = `${Root}/test_stations`

    export const Products = `${Root}/products`

    export const Product = (id: number | string) => `${Products}/${id}`
    export const ProductTests = (id: number | string) => `${Product(id)}/tests`
    export const ProductBuilds = (id: number | string) => `${Product(id)}/builds`
    export const BuildReportFilterParams = (product_id: number | string, build_id: number | string) => `${ProductBuilds(product_id)}/${build_id}/report_filter_params`
  }
}
