import React from "react";
import { setTitle } from "../helpers";
import { useParams } from "react-router-dom";
import { Result } from "../controls/result";

require("../styles/result-page.css");

export const ResultPage: React.FC = () => {
  const params = useParams();
  const resultID = params.id;

  setTitle("Result");

  return (
    <div id="result-page">
      <Result resultID={resultID as string} />
    </div>
  );
};
